import * as React from 'react'
import {graphql} from 'gatsby'
import { RootLayout as Layout } from '../Layout'
import Button from '@mui/material/Button';
import {useTranslation,Link} from "gatsby-plugin-react-i18next";

const IndexPage = ({location }) => {
    const {t} = useTranslation();
    return (
        <Layout type="full" pathname={location.pathname}>
            <p>{t("text1")} - {t("description")} </p>

            <div style={{padding: 80}}>
                <p
                    style={{
                        color: 'cornflowerblue',
                        fontSize: 50,
                        fontWeight: 'bold',
                    }}
                >
                    Gatsby Ant Design Docs Boilerplate
                </p>
                <h2>A gatsby starter to create documentation websites</h2>
                <p>
                    This is a fork of{' '}
                    <a href="https://github.com/cvluca/gatsby-starter-markdown">
                        https://github.com/cvluca/gatsby-starter-markdown
                    </a>
                </p>
                <br/>
                <div>
                    <Button
                        size="large"
                        href="https://twitter.com/jannikbuschke"
                        target="_blank"
                    >
                        Twitter
                    </Button>
                    <Button
                        size="large"
                        href=""
                        target="_blank"
                    >
                        Github
                    </Button>

                    <Button color="primary" size="large">
                        <Link to="/docs/get-started/introduction">Get Started</Link>
                    </Button>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;